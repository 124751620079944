<template>
  <div class="px-3 py-2" v-if="info">
    <b-overlay :show="loading" rounded="sm">
      <UserInfo
        class="mt-4"
        image="/img/default-avatar.png"
        :primary="info.email"
      ></UserInfo>

      <hr class="pb-0 mb-0 mt-2" />

      <dl class="mt-2 clearfix">
        <dt>{{ $t("STATUS") }}</dt>
        <dd>
          <MessageStatus :status="item.status" show-text></MessageStatus>
        </dd>

        <dt v-if="item.viewed">{{ $t("VIEWED") }}</dt>
        <dd v-if="item.viewed">
          {{
            FormatDateTime(item.viewed_date)
          }}
        </dd>
        <dt>{{ $t("AUTH_METHODS.VERIFY") }}</dt>
        <dd>
          <b-img
            class="authIcon"
            :src="authenticationImage"
          ></b-img>
          {{ showMethod }}
        </dd>
        <dt v-if="item.recalled">{{ $t("recalled") }}</dt>
        <dd v-if="item.recalled">
          {{
            FormatDateTime(item.recalled_date)
          }}
        </dd>
        <dt v-if="checkResponse">{{ $t("RESPONSED") }}</dt>
        <dd v-if="checkResponse">{{ response_status }}</dd>
      </dl>

      <RespondedMessage
        class="mt-2"
        v-if="showResponse"
        :functionbox_uuid="functionbox_uuid"
        :message_uuid="info.response_message_uuid"
      ></RespondedMessage>

      <hr class="pb-0 mb-0 mt-2" />

      <b-button
        v-if="message_link"
        variant="secondary"
            size="sm"
        class="no-border mt-2"
        @click="viewMessage"
        >{{ $t("GOTO_MESSAGE") }}
      </b-button>

      <b-button
        v-if="showRecall"
        variant="danger"
        class="btn-fill mt-2"
            size="sm"
        @click="recall"
        >{{ $t("RECALL") }}
      </b-button>
  
      <b-button
        v-if="getStatus == 10"
        variant="primary"
            size="sm"
        class="btn-fill mt-2"
        @click="resend"
        >{{ $t("RESEND") }}</b-button
      >
    </b-overlay>
  </div>
</template>
<script>
import MessageStatus from "@/components/View/MessageStatus";
import UserInfo from "@/components/Layout/UserInfo";
import RespondedMessage from "@/components/Conversation/RespondedMessage";
export default {
  components: { RespondedMessage, UserInfo, MessageStatus },
  props: ["message_link", "response", "item", "index","functionbox_uuid"],
  data() {
    return {
      loading: false,
      info: this.item,
      showDialog: false,
      showResponse: false,
      viewed: false,
      viewed_date: ""
    };
  },
  methods: {
    viewResponse: function() {
      this.showResponse = true;
    },
    onClose: function() {
      this.showDialog = false;
    },
    viewMessage: function() {
      this.$emit("gotoMessage",this.info.message_uuid);
    },
    recall: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.loading = true;
            self.$http
              .post(
                self.user.hostname + "/message/link-recall",
                {
                  link_id: self.info.id,
                }
              )
              .then(function(response) {
                self.info.recalled = 1;
                self.loading = false;
                self.info.recalled_date = response.data.recalled_date;
                self.$noty.info(self.$t("UPDATED"));
              })
              .catch(function(error) {
                self.loading = false;
                if(error.toString() == "Error: fail_4_4") return;
                if (error.response != undefined) {
                  self.$noty.error(self.$t(error.response.data));
                }
              });
        }
      })
      .catch(function(){
      }); 
    },
    resend: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.loading = true;
          self.$http
            .post(
              self.user.hostname + "/message/link-resend",
              {
                link_id: self.info.id
              }
            )
            .then(function() {
              self.loading = false;
              self.info.sent = 1;
              self.$noty.info(self.$t("UPDATED"));
            })
            .catch(function(error) {
              self.loading = false;
              if(error.toString() == "Error: fail_4_4") return;
              if (error.response != undefined) {
                self.$noty.error(self.$t(error.response.data));
              }
            });
        }
      })
      .catch(function(){
      }); 
    }
  },
  computed: {
    showRecall(){
      if(this.getStatus == 0)
      {
        return true;
      } 
      if(this.getStatus == 10)
      {
        return true;
      } 
      return false;
    },
    checkResponse() {
      if (this.response != undefined) {
        return this.response;
      } else {
        if (this.info.response != undefined) {
          return this.info.response;
        } else {
          return false;
        }
      }
    },
    response_status() {
      return this.responsed ? this.$t("YES") : this.$t("NO");
    },
    responsed() {
      return this.info.response_message_uuid != "" ? true : false;
    },
    authenticationImage() {
      return "/img/auth/" + this.info.authentication_method + ".png";
    },
    popoverTarget() {
      return "SecureLink" + this.index;
    },
    showMethod() {
      return this.$t("AUTH_METHODS." + this.info.authentication_method);
    },
    getStatus() {
      if (this.responsed) {
        return 3;
      }
      if (this.info.recalled == 1) {
        return 2;
      }
      if (this.info.viewed == 1) {
        return 1;
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return 10;
        }
      }
      return 0;
    },
    showStatus() {
      if (this.responsed) {
        return this.$t("RESPONSED");
      }
      if (this.info.recalled == 1) {
        return this.$t("recalled");
      }
      if (this.info.viewed == 1) {
        return this.$t("VIEWED");
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return this.$t("NOT_SENT");
        }
      }
      return this.$t("NOT_VIEWED");
    },
  },
  watch: {
    item: function(newItem, oldItem) {
      if (newItem.id != oldItem.id) {
        this.info = newItem;
      }
    },
  },
};
</script>
<style></style>
