<template>
  <section>

    <v-select    
      :key="redrawSelectKey"   
      label="email"
      v-model="selectedItems"  
      @close="closed"
      :disabled="disableSearch"
      :multiple="!singleSelect"
      :taggable="true"
      :map-keydown="handlers"
      :closeOnSelect="false"
      :selectable="checkSelectable"
      :dropdown-should-open="dropdownShouldOpen"
      :filterable="false"
      :filterBy="filterBy"
      :options="options"
      @search="onSearch"  
      @search:blur="searchBlur"
      :select-on-key-codes="[13, 188]"
      @option:deselected="deSelected"
      @option:selected="selectedItem"
      :placeholder="currentPlaceholder">
      <template slot="no-options">
        {{ $t('NONE_SELECTED')}}
      </template>
      <template slot="option" slot-scope="option">         
        <div v-if="option.item_type == undefined">
          {{ option.email }}
        </div>
        <div v-if="option.item_type == 'message-fax'">
          <i class="fal fa-phone"></i> {{ option.email }}
        </div>
        <div v-if="option.item_type == 'message-link'">
          <i class="fal fa-envelope" v-if="isEmail(option.email)"></i><i class="fal fa-phone" v-if="isPhone(option.email)"></i> {{ option.email }}
        </div>
        <div v-if="option.item_type == 'message-user'">
          <i class="fal fa-user"></i> {{ option.email }}
        </div>
        <div v-if="option.item_type == 'user'">
          <i class="fal fa-user"></i> {{ option.name }}, {{ option.email }}, {{ option.organisation }}
        </div>
        <div v-if="option.item_type == 'functionbox'">
          <i class="fal fa-box"></i> {{ option.name }}, {{ option.email }}, {{ option.organisation }}
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">
          {{option.email}}
        </div>
      </template>
    </v-select>
  </section>
</template>
<script>
export default {
  props: {
    placeholder: {
      default: '',
      type: String,
    },
    isNumber: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    messageType: {
      default: "",
      type: String,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    disableSearch: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    singleSelect: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    value: {
      default: () => [],
      type: Array,
    }
  },
  data() {
    return {
      redrawSelectIndex: 0,
      selectedItems: null,
      items: this.value,
      options: [],
      currentPlaceholder: '',
      debounce: null,
      searchText: '',     
    };
  },
  methods: {
    handlers(map){
      let self = this;
      return {
        ...map,
        9: e => {          
          if(e.keyCode == 9)
          {
            self.tabbed();
          }
        }
      };
    },
    filterBy() {
      return true;
    },
    searchBlur(){
      this.closed();
      this.redrawSelectIndex++;
      this.$emit('forceRedraw');
    },
    tabbed(){
      this.closed();
      this.redrawSelectIndex++;
      this.$emit('tab');
      this.$emit('forceRedraw');
    },
    dropdownShouldOpen(VueSelect) {      
      return VueSelect.search.length !== 0
    },
    closed()
    {
      let item = this.searchText;
      if(this.validateSelected(item))
      {
        if(this.singleSelect)
        {
          this.items = [];
        }        
        this.items.push(item);
        this.$emit("input", this.items);
      }
    },
    isEmail(item){
      return this.validateEmail(item);
    },
    isPhone(item){
      return this.validatePhone(item);
    },
    selectedItem(selected)
    { 
      if(selected != null)
      {
        this.items = [];
        if(Array.isArray(selected))
        {
          for (const item of selected) {
            if(item.item_type == undefined)
            {
              this.items.push(item);
            }else{
              this.items.push(item.email);
            }
          }
          this.$emit("input", this.items);
        }else{
          let item = selected;
          if(item.item_type == undefined)
          {
            this.items.push(item);
          }else{
            this.items.push(item.email);
          }
          this.$emit("input", this.items);
        }
      }
    },
    deSelected(item){
      if(item.item_type == undefined)
      {
        this.items = this.items.filter(function(filterItem){ 
          return filterItem != item;
        });
        this.$emit("input", this.items);
      }else{
        this.items = this.items.filter(function(filterItem){ 
          return filterItem != item.email;
        });
        this.$emit("input", this.items);
      }
    },
    onSearch(search, loading) {
      loading(false);
      let self = this;
      this.searchText = search;
      if(search.length < 2) {
        this.options = [];
      } else {
        if(this.debounce) {
          clearTimeout(this.debounce);
        }
        loading(true)
        this.debounce = setTimeout(async function() {
          let result = await self.$http.post(
            self.user.hostname + "/auto-complete",
            {
              message_type: self.messageType,
              search: search,
              emails: self.items,
              user_uuid: self.user_uuid,
              functionbox_uuid: self.functionbox_uuid,
            }
          );
          self.options = result.data.items;
          loading(false);
        }, 300);
      }
    },
    validateSelected(item)
    {
      if(this.isNumber)
      {
        return this.validatePhone(item);
      }else{
        return this.validateEmail(item) || this.validatePhone(item);
      }
    },
    checkSelectable(option)
    {
      if(option.item_type == undefined)
      {
        if(this.items.includes(option))
        {
          return false;
        }
        return this.validateSelected(option);
      }else{
        if(this.items.includes(option.email))
        {
          return false;
        }
        return true;
      }
    },
    filterOptions(options){
      return options;
    }
  },
  computed: {
    redrawSelectKey(){
      return "redrawSelectKey_" + this.redrawSelectIndex;
    }
  },  
  watch: { 
    selectedItems: function(newVal)
    {
      this.selectedItem(newVal);
    },
    searchText: function(newVal, oldVal) {
      if(newVal != oldVal)
      {
        if(!this.singleSelect)
        {
          let validatedOk = true;
          var separators = [' ', ',', ';'];
          let splitted = this.searchText.split(new RegExp(separators.join('|'), '')).filter(Boolean);
          let addToItems = [];
          if(splitted.length > 1)
          {
            for(let ix = 0; ix < splitted.length; ix++)
            {
              let item = splitted[ix];   
              if(this.validateSelected(item))
              {                               
                if(!this.items.includes(item))
                { 
                  if(!addToItems.includes(item))
                  {                 
                    addToItems.push(item);
                  }
                }
              }else{
                validatedOk = false;
              }
            }     
            if(validatedOk && addToItems.length != 0)
            {
              if(this.items.length > 0)
              {
                addToItems = addToItems.concat(this.items);
              }
              this.selectedItem(addToItems);
              this.$emit('forceRedraw');
            }
          }
        }
      }      
    }
  },
  mounted() {    
    if(this.placeholder == "")
    {
      this.currentPlaceholder = this.$t('ADD_SEARCH_RECIPIENTS');
    }else{
      this.currentPlaceholder = this.placeholder;
    }
    if(this.value != undefined)
    {
      if(this.value.length != 0)
      {
        let unique = this.value.filter((item, i, ar) => ar.indexOf(item) === i);
        this.selectedItems = unique;
      }
    }
  },
};
</script>