<template>
  <span>
    <div class="attachment">
      <div class="icon"><i class="fal fa-file"></i></div>
      <div class="information">
        <div class="name" v-b-tooltip.hover :title="info.name">{{ info.name }}</div>
        <div class="filesize">
          <span v-if="info.size">{{bytesToSize(info.size)}} &nbsp;</span>
            <a download href="#" @click.prevent="download" v-show="!loading" v-b-tooltip.hover :title='$t("DOWNLOAD")'>
              <i class="fal fa-download"></i> {{$t("DOWNLOAD")
            }}</a>
        </div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  props: ["item","index","message_uuid", "link_uuid", "user_uuid", "functionbox_uuid"],
  data() {
    return {
      loading: false,
      info: this.item,
      download_uuid: '',
      blob: null
    };
  },
  methods: {
    async download() {
      if(this.link_uuid)
      {
        let result = await this.$http.post(
          this.user.hostname + "/message/link/download/generate",
          {
            link_uuid: this.link_uuid,
            functionbox_uuid: this.functionbox_uuid,
            user_uuid: this.user_uuid,            
            attachment_index: this.index
          }
        );
        let downloadUrl = this.user.hostname + "/message/link/download/" + result.data.download_uuid;
        this.openUrl(downloadUrl);
      }else{
        let result = await this.$http.post(
          this.user.hostname + "/download/generate",
          {
            functionbox_uuid: this.functionbox_uuid,
            user_uuid: this.user_uuid,
            message_uuid: this.message_uuid,
            attachment_index: this.index
          }
        );
        let downloadUrl = this.user.hostname + "/download/" + result.data.download_uuid;
        this.openUrl(downloadUrl);
      }
    },
    openUrl(downloadUrl)
    {
      try {
        console.log("OPENING URL");
        let element = document.createElement("a");
        element.setAttribute('target', '_blank');
        element.style = "display: none";
        element.href = downloadUrl;
        element.download = this.info.name;
        document.body.appendChild(element);
        console.log("CLICK");
        element.click();
        document.body.removeChild(element);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
<style>
</style>