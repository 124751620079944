<template>
  <section class="view-content">
    <b-overlay :show="loading" rounded="sm">
      <div v-if="!opened && !error">
        <b-card class="mt-2 card-message">
          <template v-slot:header>&nbsp;</template>
          <b-skeleton></b-skeleton>
          <b-skeleton></b-skeleton>
          <b-card-footer> &nbsp; </b-card-footer>
        </b-card>
      </div>
      <div v-if="opened" style="position: relative">
        <div class="text-center" v-if="showNewMessages">
          <b-button class="no-border" @click="showConversation">
            <i class="fal fa-chevron-double-up"></i> &nbsp;
            {{ responses_after }} {{ $t("CONVERSATION_NEW_MESSAGES") }}
          </b-button>
        </div>

        <b-alert
          v-if="!functionbox_uuid && showArchived"
          show
          variant="secondary"
          class="mb-2"
          >{{ $t("ARCHIVED") }}
        </b-alert>

        <b-alert
          v-if="functionbox_uuid && showArchived"
          show
          variant="secondary"
          class="mb-2"
          >{{ $t("CLOSED_ISSUE") }}
        </b-alert>

        <b-alert
          v-if="
            functionbox_uuid &&
            claimedBy &&
            claimedBy.email == user.information.email
          "
          show
          variant="warning"
          >{{ $t("CLAIMED_BY") }} {{ $t('ME') }}</b-alert
        >
        <b-alert
          v-if="
            functionbox_uuid &&
            claimedBy &&
            claimedBy.email != user.information.email
          "
          show
          variant="warning"
          >{{ $t("CLAIMED_BY") }} {{ claimedBy.email }}</b-alert
        >

        <div>
          <b-button
            v-if="showCloseBtn"
            size="sm"
            @click="closeMessage"
            class="no-border"
          >
            <i class="fal fa-times"></i
            ><span class="hidden-mobile">&nbsp;{{ $t("CLOSE") }}</span>
          </b-button>

          <b-button
            v-if="isConversation"
            size="sm"
            @click="gotoMessage"
            class="no-border"
          >
            {{ $t("GOTO_MESSAGE") }}
          </b-button>

          <b-button
            @click="getInformation"
            class="no-border"
            size="sm"
            v-if="!isConversation"
          >
            <i class="fal fa-sync"></i
            ><span class="hidden-mobile">&nbsp;{{ $t("UPDATE") }}</span>
          </b-button>

          <AddMessageReply
            v-if="showComplete"
            @sentMessage="sentMessage"
            :message="message"
            :functionbox_uuid="functionbox_uuid"
            :external_system_participants="external_system_participants"
            reply-complete
            :user_uuid="user_uuid"
            ><b-button size="sm" class="no-border"
              ><i class="fal fa-reply"></i
              ><span class="hidden-mobile"
                >&nbsp;{{ $t("COMPLETE") }}</span
              ></b-button
            ></AddMessageReply
          >
          <AddMessageReply
            v-if="showRespond"
            @sentMessage="sentMessage"
            :message="message"
            :functionbox_uuid="functionbox_uuid"
            :user_uuid="user_uuid"
            ><b-button size="sm" class="no-border"
              ><i class="fal fa-reply"></i
              ><span class="hidden-mobile"
                >&nbsp;{{ $t("RESPOND") }}</span
              ></b-button
            ></AddMessageReply
          >

          <AddMessageReply
            v-if="showRespondAll"
            @sentMessage="sentMessage"
            :message="message"
            reply-all
            :functionbox_uuid="functionbox_uuid"
            :user_uuid="user_uuid"
            ><b-button size="sm" class="no-border"
              ><i class="fal fa-reply-all"></i
              ><span class="hidden-mobile"
                >&nbsp;{{ $t("RESPOND_ALL") }}</span
              ></b-button
            ></AddMessageReply
          >

          <AddMessageForward
            v-if="showForward"
            @sentMessage="sentMessage"
            :message="message"
            :functionbox_uuid="functionbox_uuid"
            :user_uuid="user_uuid"
            ><i class="fal fa-share"></i
              ><span class="hidden-mobile"
                >&nbsp;{{ $t("FORWARD") }}</span
              ></AddMessageForward
          >
        
        </div>
        <div id="printMe">
          <b-card class="mt-2 card-message">
            <template v-slot:header v-if="!isConversation">
              <div>
                <img class="icon mr-2" :src="iconImage" />
                {{ message.subject }}
              </div>
            </template>
            <UserInfo
              class="mt-2"
              image="/img/default-avatar.png"
              :primary="createdName"
              :secondary="message.creator.email"
              :optional="createdTime"
            ></UserInfo>
            <small v-if="functionBoxCreatedBy != ''"
              >{{ $t("CREATED_BY") }} {{ functionBoxCreatedBy }}</small
            >

            <small v-if="delegatedCreatedBy != ''"
              >{{ $t("CREATED_BY") }} {{ delegatedCreatedBy }}</small
            >
            <div class="mt-2" v-if="isConversation && showRecipients">
              <hr class="pb-0 mb-2 mt-2" />
              <span class="grey" v-if="recipientsNoCreator.length != 0">
                <span
                  v-for="(item, index) in recipientsNoCreator"
                  :key="index + '-recipient'"
                  ><Recipient
                    :index="index"
                    :item="item"
                    :islast="checkLastRecipient(index)"
                  ></Recipient> </span
              ></span>
            </div>
            <div class="mt-2" v-if="!isConversation">
              <div
                v-if="
                  recipientsNoCreator.length != 0 ||
                  participantsNoCreator.length != 0 ||
                  external.length != 0 ||
                  external_system_participants.length != 0
                "
              >
                <hr class="pb-0 mb-2 mt-2" />
                {{ $t("TO") }}:&nbsp;
                <span v-if="!isCreator" class="grey"
                  ><span
                    v-for="(item, index) in recipientsNoCreator"
                    :key="index + '-recipient'"
                    ><Recipient
                      :index="index"
                      :item="item"
                      :islast="checkLastRecipient(index)"
                    ></Recipient> </span
                ></span>
                <span
                  v-for="(item, index) in participantsNoCreator"
                  :key="index + '-participant'"
                  ><Participant
                    :response="message.settings.require_response"
                    :message_uuid="message.message_uuid"
                    :index="index"
                    :item="item"
                    :creator="isCreator"
                    :user_uuid="user_uuid"
                    :functionbox_uuid="functionbox_uuid"
                    :islast="checkLastParticipant(index)"
                  ></Participant>
                </span>
                <span
                  v-for="(item, index) in external"
                  :key="index + '-external'"
                  class="ms-fontSize-12"
                >
                  <SecureLink
                    :message_uuid="message.message_uuid"
                    :response="message.settings.require_response"
                    :index="index"
                    :item="item"
                    :creator="isCreator"
                    :user_uuid="user_uuid"
                    :functionbox_uuid="functionbox_uuid"
                    :islast="external.length - 1 == index"
                  ></SecureLink>
                </span>
              </div>
            </div>
            <hr class="pb-0 mb-0 mt-2" />
            <RegularMessage
              :message="message"
              :user_uuid="user_uuid"
              :functionbox_uuid="functionbox_uuid"
              v-if="regular_message"
            ></RegularMessage>
            <template v-slot:footer>
              <a @click="show = true" class="clickable"
                ><i class="ml-2 fal fa-info-circle"></i> {{ $t("DETAILS") }}</a
              >


            </template>
          </b-card>

          <b-sidebar
            id="message-information"
            v-model="show"
            lazy
            right
            no-slide
            shadow
            backdrop
          >
            <div class="px-3 py-2">
              {{ $t("MESSAGE_DETAILS") }}

              <hr class="pb-0 mb-0 mt-2 mb-3" />

              <h6 class="mb-0 no-uppercase">{{ $t("MESSAGE_UUID") }}</h6>
              {{ message.message_uuid }}

              <div v-if="message.creator.name != ''">
                <h6 class="mt-3 mb-0 no-uppercase">{{ $t("CREATOR_UUID") }}</h6>
                {{ message.creator.user_uuid }}
              </div>

              <div v-if="functionbox_uuid">
                <h6 class="mt-3 mb-0 no-uppercase">
                  {{ $t("RECIPIENT") }} {{ $t("FUNCTIONBOX.TITLE") }}
                </h6>
                {{ functionbox_uuid }}
              </div>

              <h6 class="mt-3 mb-0 no-uppercase">
                {{ $t("MESSAGE_VERSION") }}
              </h6>
              {{ message.version }}

              <div v-if="!responseType">
                <h6 class="mt-3 mb-0 no-uppercase">
                  {{ $t("LOA.TITLE_PARTICIPANT") }}
                </h6>
                <i
                  class="fal fa-info-square"
                  v-b-tooltip.hover
                  :title="$t('LOA.INFO.' + message.settings.loa_level)"
                ></i>
                LOA{{ message.settings.loa_level }}
              </div>

              <h6 class="mt-3 mb-0 no-uppercase">{{ $t("TYPE") }}</h6>
              {{ $t("MESSAGES.TYPE." + message.message_type) }}

              <h6 class="mt-3 mb-0 no-uppercase" v-if="message.settings.require_response">
                {{ $t("RESPONSE") }}
              </h6>
              <span v-if="message.settings.require_response">{{
                $t("MESSAGES.REQUESTED_RESPONSE")
              }}</span>

              <hr class="pb-0 mb-0 mt-2" />
            </div>
          </b-sidebar>


        <div v-if="showOldMessages">
          <div class="text-center mb-2">
            <b-button
              class="no-border"
              v-if="responses_before > 0"
              @click="showConversation"
            >
              <i class="fal fa-chevron-double-down"></i> &nbsp;
              {{ responses_before }} {{ $t("CONVERSATION_OLDER_MESSAGES") }}
            </b-button>
          </div>
        </div>
        </div>
      </div>
      <div v-if="error">
        <b-button
          v-if="showCloseBtn"
          @click="closeMessage"
          class="no-border"
          v-b-tooltip.hover
          :title="$t('CLOSE')"
        >
          <i class="fal fa-times"></i
          ><span class="hidden-mobile">&nbsp;{{ $t("CLOSE") }}</span>
        </b-button>
        <b-button
          @click="getInformation"
          class="no-border"
          v-if="!isConversation"
        >
          <i class="fal fa-sync"></i
          ><span class="hidden-mobile">&nbsp;{{ $t("UPDATE") }}</span>
        </b-button>
        <b-button
          @click="removeMessage"
          class="no-border"
          v-if="!isConversation && (errorMessage != 'ERROR.RECALLED')"
        >
          <i class="fal fa-trash-alt"></i
          ><span class="hidden-mobile">&nbsp;{{ $t("REMOVE") }}</span>
        </b-button>
        <b-card class="card-message text-center">
          <template v-slot:header> &nbsp; </template>
          <div class="mt-2 mb-2">
            <i class="fal fa-exclamation-circle"></i>
            {{ $t(errorMessage) }}
          </div>
        </b-card>
      </div>
    </b-overlay>
    <LoaLevel :loa_level="loa_error" v-if="!isConversation"></LoaLevel>
  </section>
</template>
<script>
import AddMessageForward from "@/components/Compose/AddMessageForward";
import AddMessageReply from "@/components/Compose/AddMessageReply";
import RegularMessage from "@/components/View/Information/RegularMessage";
import UserInfo from "@/components/Layout/UserInfo";
import Recipient from "@/components/View/Recipient";
import Participant from "@/components/View/Participant";
import SecureLink from "@/components/View/SecureLink";
import LoaLevel from "@/components/View/LoaLevel";
export default {
  name: "MessageInformation",
  props: {
    message_uuid: {
      default: "",
      type: String,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    showCloseBtn: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isConversation: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  components: {
    AddMessageForward,
    AddMessageReply,
    RegularMessage,
    LoaLevel,
    UserInfo,
    Participant,
    SecureLink,
    Recipient
  },
  data() {
    return {
      response_to_message_uuid: "",
      last_response_uuid: "",
      responses_after: 0,
      responses_before: 0,
      showAddConversation: false,
      conversation_uuid: "",
      showAllResponse: false,
      loa_error: 0,
      show: false,
      showFunctionboxLog: false,
      showDelegatedAccountLog: false,
      opened: false,
      error: false,
      loading: true,
      email: "",
      participants: [],
      external_status: [],
      external_system_participants: [],
      errorMessage: "",
      message: {},
      external: [],
      functionbox_accounts: [],
      delegated_accounts: [],
    };
  },
  methods: {
    gotoMessage() {
      this.$emit("gotoMessage", this.message.message_uuid);
    },
    sentMessage() {
      this.getInformation();
      this.getBeforeAfter();
    },
    showConversation() {
      this.$emit("showConversation", this.message.message_uuid);
    },
    createdResponse() {
      this.showAddConversation = false;
      this.getInformation();
    },
    cancelReply() {
      this.showAddConversation = false;
    },
    printBtn() {
      window.print();
      /*
      this.$htmlToPaper("printMe",{
        "styles": [
          "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
        ]
      });*/
    },
    removeMessage() {
      let self = this;
      const h = this.$createElement;
      let messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
        h(
          "p",
          { class: ["text-center mb-0 pb-0"] },
          self.$t("CONFIRM.DO_YOU_WANT_TO_REMOVE", {
            msg: self.$t("MESSAGES.TYPE." + self.message.message_type),
          })
        ),
      ]);
      if (this.isCreator) {
        messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
          h(
            "p",
            { class: ["text-center mb-0 pb-0"] },
            self.$t("CONFIRM.DO_YOU_WANT_TO_REMOVE_CREATOR", {
              msg: self.$t("MESSAGES.TYPE." + self.message.message_type),
            })
          ),
        ]);
      }
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/message/remove", {
                message_uuid: self.message_uuid,
                user_uuid: self.user_uuid,
                functionbox_uuid: self.functionbox_uuid,
              })
              .then(() => {
                self.$emit("closeMessage");
              })
              .catch(function (error) {
                if(error.toString() == "Error: fail_4_4") return;
                self.$noty.error(self.$t("ERROR.COULD_NOT_SAVE"));
              });
          }
        })
        .catch(function () {});
    },
    closeMessage: function () {
      this.$emit("closeMessage");
    },
    getBeforeAfter: function () {
      let self = this;
      this.$http
        .post(this.user.hostname + "/message/conversation/before-after", {
          message_uuid: this.message_uuid,
          functionbox_uuid: this.functionbox_uuid,
          user_uuid: this.user_uuid,
          page: 1,
          limit: 1,
        })
        .then((response) => {
          self.responses_after = response.data.after;
          self.responses_before = response.data.before;
        })
        .catch(() => {});
    },
    claim: function () {
      let self = this;
      this.$http
        .post(this.user.hostname + "/message/functionbox/claim", {
          message_uuid: this.message_uuid,
          functionbox_uuid: this.functionbox_uuid,
        })
        .then(() => {
          self.getInformation();
        })
        .catch(() => {});
    },
    noClaim: function () {
      let self = this;
      this.$http
        .post(this.user.hostname + "/message/functionbox/claim/remove", {
          message_uuid: this.message_uuid,
          functionbox_uuid: this.functionbox_uuid,
        })
        .then(() => {
          self.getInformation();
        })
        .catch(() => {});
    },
    archive: function () {
      let self = this;
      const h = this.$createElement;
      let messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
        h(
          "p",
          { class: ["text-center mb-0 pb-0"] },
          self.$t("CONFIRM.DO_YOU_WANT_TO_ARCHIVE", {
            msg: self.$t("MESSAGES.TYPE." + self.message.message_type),
          })
        ),
      ]);
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {            
            self.$http
              .post(self.user.hostname + "/message/user/archive", {
                message_uuid: self.message_uuid,
                user_uuid: self.user_uuid,
              })
              .then(() => {
                self.getInformation();
              })
              .catch(() => {});
          }
        })
        .catch(function () {});      
    },
    closeIssue: function () {
      let self = this;
      self.$http
        .post(self.user.hostname + "/message/functionbox/archive", {
          message_uuid: self.message_uuid,
          functionbox_uuid: self.functionbox_uuid,
        })
        .then(() => {
          self.getInformation();
        })
        .catch(() => {});
    },
    noArchive: function () {
      let self = this;
      if (this.functionbox_uuid != "") {
        this.$http
          .post(this.user.hostname + "/message/functionbox/archive/remove", {
            message_uuid: this.message_uuid,
            functionbox_uuid: this.functionbox_uuid,
          })
          .then(() => {
            self.getInformation();
          })
          .catch(() => {});
      } else {
        this.$http
          .post(this.user.hostname + "/message/user/archive/remove", {
            message_uuid: this.message_uuid,
            user_uuid: this.user_uuid,
          })
          .then(() => {
            self.getInformation();
          })
          .catch(() => {});
      }
    },
    checkLastParticipant(index) {
      if (this.external.length != 0) {
        return false;
      } else {
        return this.participantsNoCreator.length - 1 == index;
      }
    },
    checkLastRecipient(index) {
      if (this.message.recipients == undefined) {
        return true;
      }
      if (this.message.recipients.length == 0) {
        return false;
      } else {
        return this.recipientsNoCreator.length - 1 == index;
      }
    },
    async updateUser() {
      await this.$store.dispatch("user/fetchUser");
    },
    getInformation: function () {
      let self = this;
      this.loa_error = 0;
      this.error = false;
      this.opened = false;
      this.$http
        .post(this.user.hostname + "/message/uuid/open", {
          message_uuid: this.message_uuid,
          functionbox_uuid: this.functionbox_uuid,
          user_uuid: this.user_uuid,
        })
        .then((response) => {
          self.conversation_uuid = response.data.conversation_uuid;
          self.message = response.data.message;
          self.participants = response.data.participants;
          self.external_system_participants =
            response.data.external_system_participant;
          self.external = response.data.external;
          self.functionbox_accounts = response.data.functionbox_accounts;
          self.delegated_accounts = response.data.delegated_accounts;
          self.opened = true;
          self.loading = false;
          self.$emit("closeConversation");
          self.response_to_message_uuid = self.message.message_uuid;
          if (self.message.response_to_message != "") {
            self.response_to_message_uuid = self.message.response_to_message;
          }
          self.updateUser();
          if (!self.isConversation) {
            self.getBeforeAfter();
          }
        })
        .catch((error) => {
          self.loading = false;
          self.opened = false;
          self.error = true;
          if(error.toString() == "Error: fail_4_4") return;
          if (error.response.data != undefined) {
            if (
              error.response.data == "ERROR.LOA_LOW3" ||
              error.response.data == "ERROR.LOA_LOW2"
            ) {
              self.loa_error = parseInt(error.response.data.slice(-1));
            }
            self.errorMessage = error.response.data;
          }
        });
    },
  },
  computed: {
    showNewMessages() {
      if (this.isConversation) {
        return false;
      }
      if (this.responses_after == 0) {
        return false;
      }
      return true;
    },
    showOldMessages() {
      if (this.isConversation) {
        return false;
      }
      if (this.responses_before == 0) {
        return false;
      }
      return true;
    },
    iconImage() {
      return "/img/message_type/" + this.message.message_type + ".png";
    },
    showArchived() {
      let self = this;
      if (self.isCreator) {
        return self.message.status == this.$const.MessageStatus.ARCHIVED;
      } else {
        if (self.functionbox_uuid != "") {
          let me = self.participants.filter(
            (c) => c.user_uuid == self.functionbox_uuid
          );
          if (me.length > 0) {
            return me[0].archived == 1;
          } else {
            return false;
          }
        } else {
          if (self.user_uuid != "") {
            let me = self.participants.filter(
              (c) => c.user_uuid == self.user_uuid
            );
            return me[0].archived == 1;
          } else {
            let me = self.participants.filter(
              (c) => c.user_uuid == self.user.information.user_uuid
            );
            return me[0].archived == 1;
          }
        }
      }
    },
    direktLink() {
      return this.user.hostname.replace("/server/rest","") + "/message/" + this.message.message_uuid;
    },
    isResponse() {
      if (this.message.message_type == "system-message") {
        return false;
      } else {
        if (
          this.message.message_type == "response" ||
          this.message.message_type == "link-response"
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    regular_message() {
      if (
        this.message.message_type == "message" ||
        this.message.message_type == "response" ||
        this.message.message_type == "creator-response" ||
        this.message.message_type == "link-response" ||
        this.message.message_type == "creator-link-response"
      ) {
        return true;
      } else {
        return false;
      }
    },
    responseType() {
      return (
        this.message.message_type == "response" ||
        this.message.message_type == "link-response"
      );
    },
    createdTime() {
      return this.FormatDateTime(this.message.created_date);
    },
    createdName() {
      if (this.message.creator.name == "") {
        return this.message.creator.email;
      } else {
        if(this.validatePhone(this.message.creator.name))
        {
          return (
            this.message.creator.name
          );
        }else{
          return (
            this.message.creator.name +
            ", " +
            this.message.creator.organisation_name
          );
        }
      }
    },
    isCreator() {
      if (this.functionbox_uuid != "") {
        if(this.message.creator != undefined)
        {
          return this.message.creator.user_uuid == this.functionbox_uuid;
        }else{
          return false;
        }
      } else {
        if (this.user_uuid != "") {
          if(this.message.creator != undefined)
          {
            return this.message.creator.user_uuid == this.user_uuid;
          }else{
            return false;
          }
        } else {          
          if(this.message.creator != undefined)
          {
            return (this.message.creator.user_uuid == this.user.information.user_uuid);
          }else{
            return false;
          }
        }
      }
    },
    userIsCreator() {
      return this.user.information.user_uuid == this.message.creator.user_uuid;
    },
    functionBoxIsCreator() {
      return this.functionbox_uuid == this.message.creator.user_uuid;
    },
    DelegatedBoxIsCreator() {
      return this.user_uuid == this.message.creator.user_uuid;
    },
    participantsNoCreator() {
      if (this.functionbox_uuid != "") {
        return this.participants.filter(
          (c) => c.user_uuid != this.functionbox_uuid
        );
      } else {
        if (this.user_uuid != "") {
          return this.participants.filter((c) => c.user_uuid != this.user_uuid);
        } else {
          return this.participants.filter(
            (c) => c.email != this.user.information.email
          );
        }
      }
    },
    showRecipients() {
      if (this.message.message_type == "system-message") {
        return false;
      } else {
        if (this.message.message_type == "fax-message") {
          return false;
        }
        if (this.message.message_type == "sdk-message") {
          return false;
        }
        return true;
      }
    },
    recipientsNoCreator() {
      let self = this;
      return this.message.recipients.filter(
        (c) => c.email != self.message.creator.email
      );
    },
    functionBoxCreatedBy() {
      let me = this.functionbox_accounts.filter((c) => c.creator == 1);
      if (me.length != 0) {
        return me[0].email;
      }
      return "";
    },
    delegatedCreatedBy() {
      let me = this.delegated_accounts.filter((c) => c.creator == 1);
      if (me.length != 0) {
        return me[0].email;
      }
      return "";
    },
    claimedBy() {
      if (this.showArchived) {
        return null;
      }
      if (this.isCreator) {
        return null;
      }
      let claimedList = this.functionbox_accounts.filter(function (item) {
        return item.claimed == 1;
      });
      if (claimedList.length != 0) {
        return claimedList[0];
      } else {
        return null;
      }
    },
    haveIClaimed() {
      if (this.claimedBy != null) {
        if (this.message.creator.user_uuid == this.functionbox_uuid) {
          return false;
        } else {
          return this.claimedBy.email == this.user.information.email;
        }
      } else {
        return false;
      }
    },
    showRemoveClaim() {
      if (this.functionbox_uuid != "") {
        if (this.message.creator.user_uuid == this.functionbox_uuid) {
          return false;
        } else {
          return this.haveIClaimed;
        }
      } else {
        return false;
      }
    },
    showClaim() {
      if (this.functionbox_uuid != "") {
        if (this.claimedBy == null) {
          if (this.message.creator.user_uuid != this.functionbox_uuid) {
            return true;
          } else {
            return false;
          }
        } else {
          if(this.claimedBy.email != this.user.information.email)
          {
            return true;
          }else{
            return false;
          }
        }
      } else {
        return false;
      }
    },
    canRemove() {
      if (this.user.information.organisation.users_can_remove == 0) {
        return false;
      } else {
        if (this.functionbox_uuid != "") {
          if (this.showClaim) {
            return true;
          } else {
            if (this.haveIClaimed) {
              return true;
            } else {
              if (this.isCreator) {
                return true;
              } else {
                return false;
              }
            }
          }
        } else {
          return true;
        }
      }
    },
    showRespond() {
      if (this.message.message_type == "system-message") {
        return false;
      } else {
        if (this.message.message_type == "fax-message") {
          return !this.isCreator;
        }
        return (
          !this.isConversation &&
          !this.isCreator &&
          this.message.conversation_uuid != ""
        );
      }
    },
    showComplete() {
      if (this.message.message_type == "fax-message") {
        return !this.isCreator;
      }
      return (
        !this.isConversation &&
        this.isCreator &&
        this.message.conversation_uuid != ""
      );
    },
    showRespondAll() {
      if (this.message.message_type == "system-message") {
        return false;
      } else {
        return (
          !this.isConversation &&
          !this.isCreator &&
          this.message.conversation_uuid != ""
        );
      }
    },
    showForward() {
      if (this.message.message_type == "system-message") {
        return false;
      } else {
        if(this.IsMessage(this.message.message_type))
        {
          return !this.isConversation;
        }else{
          return false;
        }
      }
    },
  },
  watch: {
    message_uuid: function (val, oldval) {
      if (val != oldval) {
        this.getInformation();
      }
    },
  },
  mounted: function () {
    this.getInformation();
  },
};
</script>
<style></style>
