<template>
  <section v-if="settings">
      <div class="d-flex mt-2">

        <div class="flex-grow-1">
          <div class="form-control-border w-100" :class="{ 'is-focus': isFocus, 'is-invalid': !validInput && searchText != '', 'is-valid': validComponent }">
            <div class="mb-2 p-1" v-if="selectedItems.length != 0" :key="selectedItems.length">
              <b-form-tag
                tag="span"
                class="tag-selected"
                v-for="(option, index) in selectedItems"
                :key="'tagKey_' + index"
                @remove="removeSelected(index)"
              >
                <ContactInformation :item="option"></ContactInformation>
              </b-form-tag>
            </div>

            

            <b-form-input trim
              v-if="showAddParticipant"
              type="text"
              class="no-border"
              ref="searchText"
              autocomplete="off"
              @focus="focus"
              @blur.native="blurAdd"
              @update="onSearch"
              v-model="searchText"
              debounce="250"
              @keydown.down="autocompleteDown"
              @keydown.up="autocompleteUp"
              @keydown.enter="eventAdd"
              @keydown.tab="eventAdd"
            ></b-form-input>

            <div
              class="autocomplete"
              v-show="autocompleteResult.length != 0 && !autocompleteLoading && isFocus"
            >
              <b-list-group flush class="autocomplete-results">
                <b-list-group-item
                  v-for="(option, i) in autocompleteResultWithPaging"
                  :active="selected_autocomplete == i"
                  :key="i"
                  class="clickable"
                  @click.stop.prevent="selectFromAutocomplete(option, i)"
                >
                  <div v-if="option.item_type == 'message-fax'">
                    <i class="fal fa-phone"></i> {{ option.email }}
                    <i
                      v-if="canBlacklist"
                      @click.stop.prevent="blacklistItem(option.email, false)"
                      class="float-right fal fa-times"
                    ></i>
                  </div>
                  <div v-if="option.item_type == 'addressbook'">
                    <i class="fal fa-address-book"></i> {{ option.name }} ({{
                      option.email
                    }}{{ option.phone }})
                    <i
                      v-if="canBlacklist"
                      @click.stop.prevent="blacklistItem(option.email, true)"
                      class="float-right fal fa-times"
                    ></i>
                  </div>
                  <div v-if="option.item_type == 'graph'">
                    <i class="fab fa-windows"></i> {{ option.name }} (<span
                      v-if="option.email"
                      >{{ option.email }}</span
                    ><span v-if="option.phone">{{ option.phone }}</span
                    >)
                  </div>
                  <div v-if="option.item_type == 'message-link'">
                    <i class="fal fa-up-right-from-square" v-if="isEmail(option.email)"></i
                    ><i class="fal fa-phone" v-if="isPhone(option.email)"></i>
                    {{ option.email }}
                    <i
                      v-if="canBlacklist"
                      @click.stop.prevent="blacklistItem(option.email, false)"
                      class="float-right fal fa-times"
                    ></i>
                  </div>
                  <div v-if="option.item_type == 'message-user'">
                    <i class="fal fa-user"></i> {{ option.email }}
                    <i
                      v-if="canBlacklist"
                      @click.stop.prevent="blacklistItem(option.email, false)"
                      class="float-right fal fa-times"
                    ></i>
                  </div>
                  <div v-if="option.item_type == 'user'">
                    <i class="fal fa-user"></i> {{ option.name }},
                    {{ option.organisation }} ({{ option.email }})
                    <i
                      v-if="canBlacklist"
                      @click.stop.prevent="blacklistItem(option.email, false)"
                      class="float-right fal fa-times"
                    ></i>
                  </div>
                  <div v-if="option.item_type == 'functionbox'">
                    <i class="fal fa-box"></i> {{ option.name }},
                    {{ option.organisation }} ({{ option.email }})
                    <i
                      v-if="canBlacklist"
                      @click.stop.prevent="blacklistItem(option.email, false)"
                      class="float-right fal fa-times"
                    ></i>
                  </div>
                </b-list-group-item>
                <b-list-group-item                  
                  class="m-0 p-0 mt-2"
                  v-if="autocompleteResult.length > this.limit"
                >
                  <b-button size="sm" @click="clearAndFocusSearch" class="float-left">{{ $t('CLOSE') }}</b-button>
                  <b-pagination 
                    v-model="page"
                    :total-rows="autocompleteResult.length"
                    :per-page="limit"
                    pills
                    align="center"
                  ></b-pagination>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>
      </div>
  </section>
</template>
<script>
import ContactInformation from "@/components/View/ContactInformation";
export default {
  components: { ContactInformation },
  props: {
    enabledAddressbook: {
      type: Number,
      validator(value) {
        return value == 1;
      },
    },
    placeholder: {
      default: "",
      type: String,
    },
    message_type: {
      default: "",
      type: String,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    disableAdd: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isNumber: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    singleSelect: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    value: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      isFocus: false,
      autocompleteLoading: false,
      characterLimit: 2,
      limit: 10,
      page: 1,
      total_pages: 1,
      settings: null,
      selected_autocomplete: -1,
      loading: false,
      redrawSelectIndex: 0,
      selectedItems: [],
      autocompleteResult: [],
      items: this.value,
      options: [],
      currentPlaceholder: "",
      debounce: null,
      searchText: "",
    };
  },
  methods: {
    clearSearch()
    {
      this.clearAndFocusSearch();
      this.isFocus = false;
    },
    focus(){
      this.isFocus = true;
    },
    async init() {      
      if (this.placeholder == "") {
        this.currentPlaceholder = this.$t("ADD_SEARCH_RECIPIENTS");
      } else {
        this.currentPlaceholder = this.placeholder;
      }
      this.getSettings();      
      if (this.items.length != 0) {
        for (let ix = 0; ix < this.items.length; ix++) {
          await this.AddNewItem(this.items[ix]);
        }
      }
    },
    autocompleteDown() {
      if (this.selected_autocomplete < this.autocompleteResult.length) {
        this.selected_autocomplete++;
      }
    },
    autocompleteUp() {
      if (this.selected_autocomplete >= 0) {
        this.selected_autocomplete--;
      }
    },
    blurSearch() {
      this.searchText = "";
      this.autocompleteResult = [];
    },
    async MultipleParticipantsInSearch() {
      if (!this.singleSelect) {
        var separators = [" ", ",", ";"];
        this.searchText.replaceAll(",", " ");
        this.searchText.replaceAll(";", " ");
        let splitted = this.searchText
          .split(new RegExp(separators.join("|"), ""))
          .filter(Boolean);
        if (splitted.length > 1) {
          for (let ix = 0; ix < splitted.length; ix++) {
            let item = splitted[ix];
            if (this.validateSelected(item)) {
              this.searchText = this.searchText.replaceAll(item, "");
              this.searchText = this.searchText.replaceAll("  ", "");
              await this.AddNewItem(item);
            }
          }
        }
      }
    },
    async onSearch(searchText) {
      if (this.searchText == "") {
        this.$emit('IsSearching',false);
        this.clearAndFocusSearch();
      }else{
        this.$emit('IsSearching',true);
      }
      if (
        this.searchText.includes(" ") ||
        this.searchText.includes(",") ||
        this.searchText.includes(";")
      ) {
        this.MultipleParticipantsInSearch();
      } else {
        if (this.searchText.length >= this.characterLimit) {
          this.AutoComplete(searchText);
        }
      }
    },
    async AutoComplete(searchText) {
      let self = this;
      self.autocompleteLoading = true;
      let result = await this.$http.post(
        this.user.hostname + "/auto-complete",
        {
          message_type: this.message_type,
          search: searchText,
          emails: this.addresses,
          user_uuid: this.user_uuid,
          functionbox_uuid: this.functionbox_uuid,
        }
      );
      this.autocompleteResult = result.data.items.filter(function(item){
        return item != self.user.information.email
      });
      this.selected_autocomplete = -1;
      this.total_pages = Math.ceil(this.autocompleteResult.length / this.limit);
      this.page = 1;
      this.autocompleteLoading = false;
    },
    selected(items) {
      for (let ix = 0; ix < items.length; ix++) {
        this.selectedItems.push(items[ix]);
      }
      this.clearAndFocusSearch();
    },
    clearAndFocusSearch() {
      this.selected_autocomplete = 0;
      this.searchText = "";
      this.autocompleteResult = [];
      this.$emit('IsSearching',false);
      /*      this.$nextTick(() => {
        this.$refs.searchText.focus();
      });*/
    },
    selectFromAutocomplete(option) {
      //console.log("selectFromAutocomplete");
      this.selectedItems.push(option);
      this.clearAndFocusSearch();
    },
    removeSelected(index) {
      this.selectedItems.splice(index, 1);
      this.clearAndFocusSearch();
    },
    blacklistItem(address, inAddressbook) {
      let self = this;
      let confirmMessage = this.$t("CONFIRM.BLACKLIST", { msg: address });
      if (inAddressbook) {
        confirmMessage = this.$t("CONFIRM.BLACKLIST_WITH_ADDRESS", {
          msg: address,
        });
      }
      this.$bvModal
        .msgBoxConfirm(confirmMessage, {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/addressbook/blacklist", {
                organisation_uuid:
                  self.user.information.organisation.organisation_uuid,
                address: address,
                type: self.type,
                user_uuid: self.user_uuid,
                functionbox_uuid: self.functionbox_uuid,
              })
              .then(() => {
                self.AutoComplete(self.searchText);
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    isEmail(item) {
      return this.validateEmail(item);
    },
    isPhone(item) {
      return this.validatePhone(item);
    },
    closed() {
      let item = this.searchText;
      if (this.validateSelected(item)) {
        if (this.singleSelect) {
          this.items = [];
        }
        this.items.push(item);
        this.$emit("input", this.items);
      }
    },
    removeLast() {
      if (this.searchText == "") {
        this.selectedItems.pop();
      }
    },
    async eventAdd() {
      if (this.selected_autocomplete >= 0) {
        let item = this.autocompleteResult[this.selected_autocomplete];
        if (item != undefined) {
          await this.AddNewItem(item);
          this.clearAndFocusSearch();
        }
      } else {
        if (this.validInput) {
          console.log(this.searchText);
          await this.AddNewItem(this.searchText);
          this.clearAndFocusSearch();        
        }
      }
      if(this.searchText == "")
      {
        this.$emit('IsSearching',false);
      }
    },
    async blurAdd(){
      if(this.searchText == "")
      {
        this.$emit('IsSearching',false);
      }
      setTimeout(function(){                
        if (this.searchText != "") {
          this.eventAdd();
        }
      }.bind(this), 200);
    },
    async AddNewItem(item) {
      if (!this.addresses.includes(item)) {
        let exaktmatch = await this.ExactMatch(item);
        if (exaktmatch == null) {
          this.selectedItems.push(item);
        } else {
          if (exaktmatch.item_type == null) {
            this.selectedItems.push(item);
          } else {
            this.selectedItems.push(exaktmatch);
          }
        }
      }
    },
    validateSelected(item) {
      if (this.isNumber) {
        return this.validatePhone(item);
      } else {
        return this.validateEmail(item) || this.validatePhone(item);
      }
    },
    async ExactMatch(address) {
      try {
        let result = await this.$http.post(
          this.user.hostname + "/auto-complete/exaktmatch",
          {
            message_type: this.message_type,
            search: address,
            emails: this.addresses,
            user_uuid: this.user_uuid,
            functionbox_uuid: this.functionbox_uuid,
          }
        );
        return result.data;
      } catch (error) {
        return null;
      }
    },
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(this.user.hostname + "/settings/get", {
          setting: "AddressBook",
          organisation_uuid: this.user.information.organisation.organisation_uuid,
          functionbox_uuid: this.functionbox_uuid
        })
        .then(function (result) {
          self.settings = result.data;
          self.loading = false;
        })
        .catch(function (error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t("ERROR.COULD_NOT_FETCH"));
        });        
    },
  },
  computed: {
    canBlacklist() {
      if (
        this.functionbox_uuid != "" &&
        this.settings.admin_edit &&
        this.user.information.is_admin == 0
      ) {
        let self = this;
        let boxes = this.user.functionboxes.items.filter(function (item) {
          return item.functionbox_uuid == self.functionbox_uuid;
        });
        if (boxes.length > 0) {
          if (boxes[0].is_admin == 0) {
            return false;
          }
        }
      }
      return true;
    },
    autocompleteResultWithPaging() {
      if (this.autocompleteResult.length > this.limit) {
        let start = (this.page - 1) * this.limit;
        let end = start + this.limit;
        return this.autocompleteResult.slice(start, end);
      }
      return this.autocompleteResult;
    },
    showAddParticipant() {
      return (
        !this.singleSelect ||
        (this.singleSelect && this.selectedItems.length == 0)
      );
    },
    validComponent(){
      if(this.searchText != "")
      {
        return this.validInput;
      }else{
        return this.addresses.length != 0;
      }
    },
    validInput() {      
      if (this.searchText.length >= this.characterLimit) {
        return (
          this.validateSelected(this.searchText) && !this.addresses.includes(this.searchText)
        );
      }
      return null;
    },
    addresses() {
      return this.selectedItems.map(function (item) {
        if (item != undefined) {
          if (item.name == undefined) {
            return item;
          }
          if (item.phone) {
            return item.phone;
          }
          return item.email;
        }
      });
    },
    redrawSelectKey() {
      return "redrawSelectKey_" + this.redrawSelectIndex;
    },
  },
  watch: {
    addresses() {
      this.$emit("input", this.addresses);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
</style>