<template>
  <span v-if="loaded" :key="showKey">

    <span v-if="showComponent">
      <span v-if="message" @click="showBtn">
        <slot></slot>
      </span>
     
      <AddLinkReply_4_2 v-if="link_message && !checkValidVersion('4.3.0')"
        :show="show"
        :reply_loa="reply_loa"
        :reply_attachments="reply_attachments"
        :reply_subject="reply_subject"
        :reply_body="reply_body"
        :reply_emails="reply_emails"
        :reply_message_uuid="reply_message_uuid"
        :user_uuid="user_uuid"
        :functionbox_uuid="functionbox_uuid"
        @changeShow="changeShow"
        @sentMessage="sentMessage"
      >
      </AddLinkReply_4_2>
      
      <AddSefosMessage_4_2
        v-if="regular_message && !checkValidVersion('4.3.0')"
        :show="show"
        :reply_loa="reply_loa"
        :reply_attachments="reply_attachments"
        :reply_subject="reply_subject"
        :reply_body="reply_body"
        :reply_emails="reply_emails"
        :reply_message_uuid="reply_message_uuid"
        :user_uuid="user_uuid"
        :functionbox_uuid="functionbox_uuid"
        @changeShow="changeShow"
        @sentMessage="sentMessage"
      >
      </AddSefosMessage_4_2>


      <AddSefosMessage
        v-if="checkValidVersion('4.3.0')"
        :show="show"
        :reply_loa="reply_loa"
        :reply_attachments="reply_attachments"
        :reply_subject="reply_subject"
        :reply_body="reply_body"
        :reply_emails="reply_emails"
        :reply_message_uuid="reply_message_uuid"
        :user_uuid="user_uuid"
        :functionbox_uuid="functionbox_uuid"
        @changeShow="changeShow"
        @sentMessage="sentMessage"
      >
      </AddSefosMessage>

      
    </span>
  </span>
</template>
<script>
import AddLinkReply_4_2 from "@/components/Compose/Message/LinkReply_4_2";
import AddSefosMessage_4_2 from "@/components/Compose/Message/SefosMessage_4_2";
import AddSefosMessage from "@/components/Compose/Message/SefosMessage";
export default {
  components: { AddLinkReply_4_2, AddSefosMessage, AddSefosMessage_4_2 },
  props: {
    replyAll: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    replyComplete: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    message: {
      default: null,
      type: Object,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    external_system_participants: {
      default: null,
      type: Array,
    }
  },
  data() {
    return {
      showIndex: 0,
      showComponent: true,
      reply_identifier: "",
      reply_message_uuid: "",
      reply_subject: "",
      reply_body: "",
      reply_emails: [],
      reply_loa: 3,
      reply_metadata: null,
      reply_attachments: [],
      show: false
    };
  },
  methods: {
    showBtn() {
      this.show = true;
    },
    changeShow(val) {
      this.show = val;
      this.showIndex++;
    },
    sentMessage() {
      this.showIndex++;
      this.show = false;
      this.$emit("sentMessage");
    },
    getEmails(recipients) {
      let emails = "";
      for (let ix = 0; ix < recipients.length; ix++) {
        let item = this.message.recipients[ix];
        if (item.email != this.currentEmail) {
          if (item.type == "SEFOS" || item.type == "LINK") {
            if (emails == "") {
              emails = item.email;
            } else {
              emails = ", " + item.email;
            }
          }
        }
      }
      return emails;
    },
    buildReplyContent() {
      let to = this.getEmails(this.message.recipients);
      let sent = this.FormatDateTime(this.message.created_date);
      let content =
        "From: " +
        this.message.creator.name +
        " - " +
        this.message.creator.email;
      content = content + "\nSent: " + sent;
      content = content + "\nTo: " + to;
      content = content + "\nSubject: " + this.message.subject;
      content = content + "\n\n" + this.message.body;
      return content;
    },
  },
  computed: {
    showKey(){
      return "showKey" + this.showIndex;
    },
    loaded() {
      if(this.regular_message)
      {
        return (this.reply_subject != "" && this.message && this.reply_emails.length != 0);
      }
      if(this.link_message)
      {
        return (this.reply_subject != "" && this.message && this.reply_emails.length != 0);
      }
      if (this.message.message_type == "sdk-message") {
        if (this.replyComplete) {
          return this.external_system_participants != null && this.message && this.reply_identifier != "";
        }else{
          return this.message.creator.external_system.metadata.FromParticipantFunctionId != "" && this.message && this.reply_identifier != "";
        }
      }
      if (this.message.message_type == "fax-message") {
        return this.message && this.reply_identifier != "";
      }
      return false;
    },
    regular_message() {
      if (
        this.message.message_type == "message" ||
        this.message.message_type == "response" ||
        this.message.message_type == "creator-response"
      ) {
        return true;
      } else {
        return false;
      }
    },
    link_message() {
      if (
        this.message.message_type == "link-response" ||
        this.message.message_type == "creator-link-response"
      ) {
        return true;
      } else {
        return false;
      }
    },
    currentEmail() {
      let currentEmail = this.user.information.email;
      return currentEmail;
    },
    currentSignature() {
      let self = this;
      let currentSignature = this.user.information.signature;
      if (this.functionbox_uuid != "") {
        let me = this.user.functionboxes.items.filter(
          (c) => c.functionbox_uuid == self.functionbox_uuid
        );
        if (me.length != 0) {
          currentSignature = me[0].signature;
        }
      } else {
        if (this.user_uuid != "") {
          let me = this.user.delegatedboxes.items.filter(
            (c) => c.user_uuid == self.user_uuid
          );
          if (me.length != 0) {
            currentSignature = me[0].signature;
          }
        }
      }
      return currentSignature;
    },
  },
  mounted() {
    this.reply_loa = this.message.settings.loa_level;
    if (this.message.response_to_message == "") {
      this.reply_subject =
        this.$t("RESPONSE_START") + " " + this.message.subject;
    } else {
      this.reply_subject = this.message.subject;
    }
    if (this.currentSignature != "") {
      this.reply_body =
        "\n\n----------------------------\n" +
        this.currentSignature +
        "\n\n---------------------------------------------------------\n" +
        this.buildReplyContent();
    } else {
      this.reply_body =
        "\n\n---------------------------------------------------------\n" +
        this.buildReplyContent();
    }
    this.reply_message_uuid = this.message.message_uuid;
    if (this.regular_message) {
      if ( (this.replyAll) || (this.replyComplete)) {
        for (let ix = 0; ix < this.message.recipients.length; ix++) {
          let item = this.message.recipients[ix];
          if (item.email != this.currentEmail) {
            if (item.type == "SEFOS" || item.type == "LINK") {
              this.reply_emails.push(item.email);
            }
          }
        }        
        if(this.replyAll)
        {
          this.reply_emails.push(this.message.creator.email);
        }
      } else {
        this.reply_emails.push(this.message.creator.email);
      }
    } else if (this.link_message) {
      if (this.replyAll || (this.replyComplete)) {
        this.showComponent = false;
      }
      this.reply_emails.push(this.message.creator.email);
    } else if (this.message.message_type == "sdk-message") {
      if (this.replyAll) {
        this.showComponent = false;
      } else {
        if (this.replyComplete) {
          let SdkRecipient = this.external_system_participants[0];
          this.reply_metadata = SdkRecipient.metadata;
          this.reply_identifier = SdkRecipient.metadata.ToParticipantFunctionId;
        }else{
          if(this.message.creator.external_system != undefined)
          {
            this.reply_metadata = this.message.creator.external_system.metadata;
            this.reply_identifier = this.message.creator.external_system.metadata.FromParticipantFunctionId;
          }
        }
      }
    } else if (this.message.message_type == "fax-message") {
      this.reply_attachments = this.message.attachments;
      if (this.replyAll) {
        this.showComponent = false;
      } else {
        if(this.message.creator.external_system != null)
        {
          this.reply_identifier = this.message.creator.external_system.metadata.FromFaxNumber.replace("00", "+");
        }else{
          this.reply_identifier = "";
        }
      }
    }
  },
};
</script>