<template>
  <section class="mt-2" v-if="showContent">
    <div class="bodytext" v-html="compiledMarkdown"></div>
  </section>
</template>
<script>
//import { marked } from 'marked'
export default {
  components: {},
  props: ["body"],
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    compiledMarkdown: function() {
      //return marked(this.messageContent);
      return this.messageContent;
    },
    messageContent() {
      //return "<html><head><link href='https://"+window.location.hostname+"/css/iframe.css' rel='stylesheet'></head><body>"+this.body+"</body></html>";
      let text =  this.body.trim();
      //text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
      return text;
    },
    showContent(){
      if(this.body.trim() == "")
      {
        return false;
      }else{
        return true;
      }
    }
  },
  mounted() {
  },
};
</script>
<style></style>
