<template>
<span v-if="status != undefined">
  <span :title="MessageDescription" v-b-tooltip.hover><i :class="icon"></i></span><span v-if="showText">&nbsp;{{MessageDescription}}</span>
</span>
</template>
<script>
export default {
  components: {},
  props: {
    status: {
      default: "",
      type: [String, Number],
    },
    showText: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    }
  },
  data() {
    return {
    }
  },
  methods: {   
  },
  computed: {    
    icon(){
      let iconClass = "";
      switch(this.status)
      {
            case this.$const.MessageStatus.CREATED: iconClass = "fal fa-envelope"; break;
            case this.$const.MessageStatus.READY_TO_SEND: iconClass = "fal fa-paper-plane-top"; break;
            case this.$const.MessageStatus.SEND_ENQUEUED: iconClass = "fal fa-calendar-arrow-up"; break;
            case this.$const.MessageStatus.WAITING_FOR_RETRY: iconClass = "fal fa-clock-rotate-left"; break;
            case this.$const.MessageStatus.SEND_IN_PROGRESS: iconClass = "fal fa-inbox-out"; break;
            case this.$const.MessageStatus.SEND_ATTEMPT_FAILED: iconClass = "fal fa-triangle-exclamation"; break;
            case this.$const.MessageStatus.SEND_FAILURE: iconClass = "fal fa-circle-xmark"; break;
            case this.$const.MessageStatus.SEND_SUCCESS: iconClass = "fal fa-envelope-circle-check"; break;
            case this.$const.MessageStatus.WAITING_FOR_RECEIPT: iconClass = "fal fa-user-clock"; break;
            case this.$const.MessageStatus.ACKNOWLEDGED: iconClass = "fal fa-user-check"; break;
            case this.$const.MessageStatus.ACKNOWLEDGED_WITH_WARNING: iconClass = "fal fa-circle-exclamation-check"; break;
            case this.$const.MessageStatus.NOTIFIED_RECIPIENT: iconClass = "fal fa-bell"; break;
            case this.$const.MessageStatus.RECEIVED: iconClass = "fal fa-envelope-open-text"; break;
            case this.$const.MessageStatus.RECEIVED_WITH_WARNINGS: iconClass = "fal fa-bell-exclamation"; break;
            case this.$const.MessageStatus.DOWNLOADED: iconClass = "fal fa-download"; break;
            case this.$const.MessageStatus.DELETED: iconClass = "fal fa-trash"; break;
            case this.$const.MessageStatus.NOT_FOUND: iconClass = "fal fa-magnifying-glass-minus"; break;
            case this.$const.MessageStatus.UNKNOWN: iconClass = "fal fa-circle-question"; break;
            case this.$const.MessageStatus.SENT_WITHOUT_NOTIFICATION: iconClass = "fal fa-bell-slash"; break;
            case this.$const.MessageStatus.RESPONDED: iconClass = "fal fa-comment-check"; break;
            case this.$const.MessageStatus.RECALLED: iconClass = "fal fa-undo"; break;
            case this.$const.MessageStatus.VIEWED: iconClass = "fal fa-envelope-open"; break;
            case this.$const.MessageStatus.NOT_VIEWED: iconClass = "fal fa-envelope"; break;
            case this.$const.MessageStatus.ARCHIVED: iconClass = "fal fa-archive"; break;
            case this.$const.MessageStatus.RECEIPT_TIMEOUT: iconClass = "fal fa-alarm-exclamation"; break;
            case this.$const.MessageStatus.ACCEPTED: iconClass = "fal fa-check"; break;
            case this.$const.MessageStatus.REJECTED: iconClass = "fal fa-vote-nay"; break;
            case this.$const.MessageStatus.ABORTED: iconClass = "fal fa-ban"; break;
      }  	
      return iconClass;
    },
    MessageStatus(){
      return this.status;
    },
    MessageDescription(){
      return this.$t('MESSAGE_STATUS.' + this.status);
    }
  },
  mounted() {     
  },
};
</script>
<style></style>
